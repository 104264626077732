

















































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

@Component
export default class Terminos extends Mixins(FormValidator) {
  @Prop({ required: false })
  private tipo!: number;
}
