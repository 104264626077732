














































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Terminos from "@/components/Common/Terminos.vue";

@Component({
  components: {
    Terminos
  }
})
export default class TerminosYCondicionesComponent extends Vue {
  @Prop({ required: false })
  private visible!: boolean;
  @Prop({ required: false })
  private footer!: boolean;
  @Prop({ required: false })
  private tipo!: number;

  private bTerminos: boolean = false;

  @Watch("visible")
  private watchVisible(newValue) {
    this.bTerminos = newValue;
  }

  private mounted() {}

  private cerrarTerminos(termsAceptar) {
    if (termsAceptar == true) {
      this.$emit("closeTerminos", {
        footer: this.footer,
        response: true
      });
      this.bTerminos = false;
    } else if (termsAceptar == false) {
      this.$emit("closeTerminos", {
        footer: this.footer,
        response: false
      });
      this.bTerminos = false;
    } else {
      this.$emit("closeTerminos", {
        footer: this.footer,
        response: null
      });
      this.bTerminos = false;
    }
  }

  private onHide() {}
}
